<template>
    <div>
      <ul ref="messageList" style="height: 500px; overflow-y: scroll">
        <message-item
            v-for="(message, index) in messages"
            :key="message.id"  
            :message="message"
            :userid="userid"
        ></message-item>
      </ul>
      <message-input @sendMessage="addMessage" :username="username" :imgsrc="imgsrc"></message-input>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Echo from 'laravel-echo';
  import Pusher from 'pusher-js';
  import MessageItem from './MessageItem.vue';
  import MessageInput from './MessageInput.vue';
  
  export default {
    async mounted() {
      console.log(this.userid);
      try {
        // Fetch messages
        const response = await axios.get('http://localhost:8000/api/get-messages/1');
        this.messages = response.data;
  
        // Scroll to bottom after messages are fetched
        this.$nextTick(() => {
          this.scrollToBottom();
        });
  
        // Set up Pusher
        Pusher.logToConsole = true;
        window.Pusher = Pusher;
  
        const echo = new Echo({
          broadcaster: 'pusher',
          key: '041cbd210a55d37b02a7',
          cluster: 'ap1',
          forceTLS: true
        });
  
        echo.channel('chat-channel')
          .listen('MessageSent', (event) => {
            this.messages.push(event);
            // Scroll to bottom after a new message is added
            this.$nextTick(() => {
              this.scrollToBottom();
            });
          });
        
      } catch(error) {
        console.error('Error fetching messages:', error);
      }
    },
    props: ["username", "imgsrc", "userid"],
    components: {
      MessageItem,
      MessageInput
    },
    data() {
      return {
        messages: [
          // Example messages
          {
            imgSrc: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp",
            name: "Brad Pitt",
            time: "12 mins ago",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            align: "start"
          },
          // Add other example messages here
        ]
      };
    },
    methods: {
      async addMessage(newMessage) {
        try {
          const response = await axios.post('http://localhost:8000/api/send-messages', newMessage);
          // The new message will be pushed to the messages array via Pusher
        } catch (error) {
          console.error('Error adding message:', error);
        }
      },
      scrollToBottom() {
        const messageList = this.$refs.messageList;
        messageList.scrollTop = messageList.scrollHeight;
      }
    },
    watch: {
      messages() {
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    }
  };
  </script>
  