<template>
    <div>
      <li class="mb-3">
        <div class="form-outline form-white">
          <textarea
            v-model="messageText"
            class="form-control"
            id="textAreaExample3"
            rows="4"
            style="background-color: transparent;"
            @keyup.enter="sendMessageOnEnter"
          ></textarea>
          <label class="form-label" for="textAreaExample3">Message</label>
        </div>
      </li>
      <button
        type="button"
        class="btn btn-light btn-lg btn-rounded float-end"
        @click="sendMessage"
      >Send</button>
    </div>
  </template>
  
  <script>
  import moment from 'moment-timezone';
  export default {
    props: ['username', 'imgsrc'],
    data() {
      return {
        messageText: ''
      };
    },
    methods: {
      getCurrentDateInJakartaTimezone() {
        // Get current date and time in GMT+7 (Jakarta timezone)
        return moment().tz('Asia/Jakarta').format('YYYY-MM-DD HH:mm:ss');
      },
      sendMessage() {
        if (this.messageText.trim()) {
          this.$emit('sendMessage', {
            imgsrc: this.imgsrc, // Placeholder image source
            name: this.username, // Placeholder name, replace as needed
            time: this.getCurrentDateInJakartaTimezone(),
            message: this.messageText,
            align: "end",
            conversation_id: 1
          });
          this.messageText = '';
        }
      },
      sendMessageOnEnter(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            this.sendMessage();
        }
      },
    },
    mounted() {
        console.log(this.username)
        console.log(this.imgsrc)
    }
  };
  </script>
  