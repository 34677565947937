<template>
  <div class="col-md-6 col-lg-5 col-xl-5 mb-4 mb-md-0">
    <h3 class="font-weight-bold mb-3 text-center text-white"><b>Para Sobat TeTa!</b></h3>
    <div class="card mask-custom">
      <div class="card-body">
        <ul class="list-unstyled mb-0">
          <li
            v-for="(chat, index) in chats"
            :key="index"
            class="p-2 border-bottom"
            :style="{ borderBottom: '1px solid rgba(255,255,255,.3) !important' }"
          >
            <a href="#!" class="d-flex justify-content-between link-light">
              <div class="d-flex flex-row">
                <img
                  :src="chat.user_profile_picture"
                  alt="avatar"
                  class="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                  width="60"
                />
                <div class="pt-1">
                  <p class="fw-bold mb-0">{{ chat.user_name }}</p>
                  <p class="small text-white">{{ chat.message }}</p>
                </div>
              </div>
              <div class="pt-1">
                <p class="small text-white mb-1">{{ chat.time }}</p>
                <span v-if="chat.badge" class="badge bg-danger float-end">{{ chat.badge }}</span>
                <span v-else class="text-white float-end"><i class="fas fa-check" aria-hidden="true"></i></span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      chats: []
    };
  },
  mounted() {
    this.fetchChats();
  },
  methods: {
    fetchChats() {
      axios.get('/api/get-conversation')
        .then(response => {
          this.chats = response.data.data.map(chat => ({
            message: chat.message,
            time: chat.time,
            user_name: chat.user_name,
            user_profile_picture: chat.user_profile_picture,
            badge: chat.badge || null
          }));
        })
        .catch(error => {
          console.error('Error fetching chats:', error);
        });
    }
  }
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}
</style>
