<template>
    <li class="d-flex justify-content-between mb-4">
      <img
        v-if="message.sender_id != userid"
        :src="message.imgsrc"
        alt="avatar"
        class="rounded-circle d-flex align-self-start ms-3 shadow-1-strong"
        width="60"
      />
      <div class="card mask-custom w-100" :class="{'me-3': message.sender_id == userid, 'ms-3': message.sender_id != userid}">
        <div class="card-header d-flex justify-content-between p-3" style="border-bottom: 1px solid rgba(255,255,255,.3);">
          <p class="fw-bold mb-0">{{ message.name }}</p>
          <p class="text-light small mb-0"><i class="far fa-clock"></i> {{ message.time }}</p>
        </div>
        <div class="card-body">
          <p class="mb-0">{{ message.message }}</p>
        </div>
      </div>
      <img
        v-if="message.sender_id == userid"
        :src="message.imgsrc"
        alt="avatar"
        class="rounded-circle d-flex align-self-start me-3 shadow-1-strong"
        width="60"
      />
    </li>
  </template>
  
  <script>
  export default {
    props: {
      message: Object,
      userid: String,
    }
  };
  </script>
  
  <style scoped>
  .d-flex.justify-content-between.mb-4 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .card-body {
    padding: 1rem;
  }
  </style>
  